import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Icon from "@material-ui/core/Icon";
import LockIcon from "@material-ui/icons/Lock";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

//styles

import styles from "assets/jss/nextjs-material-kit/components/badgeStyle.js";
import customStyles from "assets/jss/custom/customStyles.js";
import { SvgIcon } from "@material-ui/core";

const useStyles = makeStyles({
  ...styles,
  ...customStyles,
});


function SDAIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 159.38 137.521">
      <path fill="#FFFFFF" d="M8.532,47.978c0-51.703,71.742-51.945,71.742-6.289c0-45.414,72.258-45.906,72.258,6.289
	c0,38.789-41.883,50.469-72,81.98C51.524,99.188,8.532,87.754,8.532,47.978"/>
    </SvgIcon>
  );
}

function SDA2Icon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 83.803 43.662">
      <path fill="#FFFFFF" d="M79.358,20.125v-0.442c-0.171-0.049-0.435-0.005-0.44-0.22c0.479-0.463,0.014-1.874,0.335-2.495
	c0-0.002,0.001-0.004,0.003-0.006c0-0.001,0-0.001,0-0.002c0.011-0.019,0.022-0.036,0.034-0.054c0.02-0.033,0.042-0.062,0.068-0.089
	c-0.046-0.09-0.099-0.176-0.156-0.256c-0.187-0.264-0.427-0.474-0.726-0.626c-0.449-0.064-0.431-0.596-0.882-0.662
	c0.021-0.097-0.003-0.149-0.049-0.178c-0.011-0.02-0.024-0.042-0.044-0.066c-0.065-0.083-0.14-0.094-0.22-0.137
	c-0.097-0.053-0.176-0.117-0.249-0.194c-0.035-0.038-0.07-0.071-0.106-0.102c-0.045-0.13-0.115-0.237-0.218-0.311
	c-0.106-0.078-0.249-0.12-0.438-0.114v-0.882c-0.647-0.015-0.818-0.504-1.102-0.882c0.159-0.675-0.382-0.649-0.222-1.323
	c0.117-0.692,0.387-1.229,0.661-1.764c0.071-0.518,0.349-0.827,0.221-1.543c-0.171-0.049-0.256-0.185-0.221-0.441
	c-1.056-0.413-2.156-0.783-3.307-1.103c-0.502-0.062-0.829,0.054-0.882,0.441c-0.462-0.093-0.583,0.151-0.882,0.221
	c-1.402,0.152-2.127-0.373-3.528-0.221c0.041-0.188-0.085-0.208-0.221-0.22c-0.692,0.104-0.583-0.593-1.103-0.662
	c-0.078-0.056-0.381,0.073-0.44,0c-0.014-0.017,0.017-0.217,0-0.22c-0.181-0.039-0.997,0.128-1.103,0
	c-0.015-0.019,0.018-0.217,0-0.221c-0.312-0.077-1.046-0.01-1.103-0.44c-0.693,0.105-0.861-0.315-1.323-0.441
	c-0.141-0.447-1.031-0.145-1.103-0.662c-0.515,0.073-0.911,0.265-1.322,0.441c-0.079-0.055-0.383,0.072-0.441,0
	c-0.017-0.021,0.022-0.213,0-0.22c-0.122-0.043-0.329,0.061-0.44,0c-0.094-0.051-0.143-0.406-0.221-0.441
	c-0.122-0.055-0.313,0.042-0.441,0c-0.299,0.068-0.183,0.552-0.22,0.882c0.094,0.461-0.151,0.584-0.221,0.882v1.764h-1.323
	C55.092,7.25,55.444,8.63,55.104,9.32c-0.313-0.019-0.625-0.036-0.661,0.22c-0.513,0.071-0.848-0.035-1.104-0.22
	c-0.35-0.019-0.841,0.105-0.882-0.221c-0.463,0.272-0.859,0.61-1.323,0.881h-1.103c-0.05-0.316-0.658-0.076-0.661-0.44
	c-0.201-0.136-0.956,0.181-1.103,0c-0.023-0.029,0.03-0.203,0-0.22c-0.092-0.051-0.369,0.072-0.44,0
	c-0.074-0.074,0.056-0.351,0-0.441c-0.135-0.013-0.262-0.033-0.221-0.221v-0.22c-0.459-0.129-0.713-0.464-1.103-0.662v0.221
	c0.107,0.695-0.397,0.778-0.221,1.543c-0.053,0.027-0.097,0.062-0.137,0.102c-0.003,0.003-0.007,0.007-0.01,0.01
	c-0.037,0.038-0.068,0.08-0.099,0.124c-0.003,0.006-0.007,0.012-0.011,0.017C46,9.837,45.974,9.884,45.947,9.932
	c-0.03,0.053-0.06,0.107-0.092,0.159c0,0,0,0.001,0,0.001c-0.101,0.161-0.224,0.299-0.452,0.33
	c-0.008-0.005-0.017-0.008-0.024-0.014c-0.014,0.017-0.032,0.029-0.054,0.036c-0.133,0.045-0.362-0.017-0.493-0.035
	c-0.163-0.022-0.323-0.062-0.484-0.094c-0.072-0.054-0.146-0.108-0.268-0.114c-0.036-0.154-0.206-0.173-0.324-0.243
	c-0.019-0.011-0.033-0.022-0.047-0.034c-0.007-0.007-0.013-0.014-0.018-0.02c-0.01-0.012-0.02-0.024-0.027-0.04
	c-0.004-0.007-0.008-0.015-0.01-0.022c-0.01-0.024-0.015-0.05-0.017-0.083h-0.661c-0.036,0.035-0.063,0.075-0.091,0.12
	c-0.005,0.01-0.01,0.023-0.016,0.034c-0.019,0.037-0.035,0.075-0.049,0.116c-0.004,0.01-0.006,0.022-0.009,0.033
	c-0.015,0.046-0.025,0.094-0.034,0.145c-0.001,0.006-0.002,0.012-0.002,0.019c-0.01,0.058-0.017,0.119-0.021,0.184l-0.002,0.018
	c-0.006,0.103-0.01,0.211-0.008,0.322c0,0.002,0,0.005,0,0.008v0.004c0.002,0.115,0.006,0.232,0.01,0.351
	c0.003,0.093,0.007,0.186,0.008,0.278v0.039c0.002,0.108,0,0.213-0.006,0.315c0.104,0.691-0.594,0.582-0.662,1.102
	c-0.24,0.054-0.148,0.438-0.441,0.441c-0.042,0.179-0.221,0.22-0.221,0.442c-0.645-0.058-0.676,0.498-1.322,0.44
	c-0.42,0.094-0.554,0.475-1.103,0.441c-0.151,0.435-0.589,0.587-1.103,0.661c-0.055,0.24-0.439,0.149-0.439,0.441
	c-0.181,0.042-0.221,0.222-0.442,0.221c0.183,0.576-0.667,1.209,0,1.543c0.214,0.006,0.171,0.27,0.222,0.441
	c-0.189-0.04-0.209,0.086-0.222,0.22c0.078,0.291,0.388,0.347,0.442,0.662c-0.064,0.356,0.206,0.38,0.22,0.662v0.883
	c-0.239,0.053-0.149,0.438-0.44,0.441c0.093,0.42,0.475,0.554,0.44,1.102c0.507-0.067,0.245,0.637,0.661,0.662
	c-0.02,0.312-0.037,0.624,0.221,0.662v1.983h-0.662c0.035-0.255-0.05-0.392-0.22-0.44c-0.007-0.214-0.271-0.171-0.44-0.22
	c0.039-0.188-0.087-0.208-0.222-0.221c0.036-0.256-0.05-0.392-0.222-0.441c-0.253-0.261-0.248-0.779-0.22-1.323
	c-0.349-0.019-0.841,0.106-0.882-0.221c-0.519-0.005-0.648,0.38-1.322,0.221c-0.603-0.161-0.444,0.438-0.883,0.441h-0.22
	c-0.055-0.24-0.438-0.149-0.441-0.441c-0.201-0.137-0.954,0.18-1.103,0c-0.143-0.176-0.16-0.401-0.44-0.441
	c0.075,0.01-0.119,0.349-0.222,0.22c-0.035-0.044,0.029-0.169,0-0.22c-0.718,0.091-0.568,1.048-0.881,1.543
	c-1.623-0.08-3.031,0.057-4.41,0.22c-0.694,0.335-1.324,0.735-2.205,0.882c-0.09,0.131-0.331,0.11-0.441,0.221
	c-0.082,0.082-0.133,0.381-0.221,0.441c-0.262,0.18-0.783,0-0.881,0.441c-0.179,0.042-0.221,0.221-0.441,0.221
	c-0.586-0.259-1.299,0.423-1.322,0c-0.675-0.16-0.805,0.225-1.324,0.22c-0.708,0.195-0.303-0.727-0.882-0.662
	c-0.093-0.42-0.63-0.398-0.441-1.102c-0.501,0.062-0.829-0.054-0.882-0.441c-1.048-0.128-2.033-0.319-3.307-0.221
	c-0.024,0.475-0.312,0.665-0.221,1.103c0.008,0.036,0.211,0.034,0.221,0.22c0.009,0.181,0.247,0.219,0.221,0.441
	c0.039,0.775-0.746,0.725-0.883,1.323c-0.463,0.271-0.859,0.61-1.322,0.882c-0.121,0.321,0.256,1.14-0.221,1.104
	c-0.094-0.421-0.631-0.4-0.441-1.104c-0.822,0.235-0.35-0.827-1.104-0.662c-0.221-0.293-0.207-0.82-0.66-0.882
	c-0.072-0.515-0.266-0.911-0.441-1.323c-0.004-0.41-0.404-0.27-0.662-0.441c-0.08-0.055-0.148-0.409-0.22-0.441
	c-0.005-0.002-0.011-0.003-0.015-0.004c-0.124-0.042-0.303,0.045-0.426,0.004c-0.055,0.239-0.439,0.149-0.441,0.441
	c-0.128,0.084-0.258,0.165-0.436,0.201c-0.067,0.014-0.141,0.022-0.227,0.019v1.543c-0.581,0.154-0.466,1.004-1.102,1.103
	c0.271,0.655-0.418,0.661-0.441,1.102c-0.01,0.181-0.246,0.22-0.221,0.443v1.542c-0.062,0.356,0.208,0.379,0.221,0.662
	c-0.035,0.256,0.049,0.392,0.221,0.44c0.732-0.021,1.318,0.103,1.836,0.295c0.133,0.049,0.262,0.104,0.387,0.161
	c0.02,0.009,0.041,0.018,0.061,0.028c0.123,0.058,0.244,0.117,0.363,0.179v1.102c0.549-0.034,0.682,0.346,1.102,0.44
	c0.003,0.292,0.387,0.202,0.441,0.441c0.304-0.009,0.484,0.104,0.66,0.221c-0.039,0.188,0.087,0.208,0.221,0.22
	c0.922,0.187,1.043-0.429,1.766-0.441c-0.273-0.713,0.713-0.168,0.44-0.881c0.123-0.317,0.343-0.538,0.661-0.661
	c0.07,0.298,0.314,0.421,0.221,0.881c0.09,0.532-0.402,0.479-0.221,1.103c0.838-0.338,1.464-0.888,2.426-1.103
	c0.852,0.544,1.273,1.52,2.646,1.543c0.003-0.291,0.387-0.201,0.44-0.441c0.267-0.084,0.311-0.448,0.441-0.662
	c0.156-0.252,0.286-0.419,0.441-0.661c0.012-0.134,0.033-0.262,0.221-0.22c0.049-0.172,0.006-0.435,0.221-0.441
	c0.493-0.242,1.104-0.366,1.544-0.662c0.792,0.059,1.062-0.408,1.763-0.441c0.625,0.037,0.92,0.404,1.543,0.441h2.646
	c0.314-0.053,0.373-0.363,0.662-0.441c0.057-0.31,0.483-0.251,0.662-0.44c0.516-0.074,0.91-0.266,1.322-0.441
	c0.32,0.12,1.139-0.258,1.103,0.22c0.171,0.05,0.435,0.006,0.441,0.221c0.253,0.693-0.347,0.535-0.441,0.882
	c-0.388,0.053-0.503,0.379-0.441,0.882c-0.162,0.971,0.714,0.903,0.883,1.543c0.224,0.29,0.216,0.813,0.44,1.102
	c0.281,0.013,0.305,0.283,0.662,0.22c-0.01,0.305,0.104,0.485,0.219,0.662c0.625-0.182,0.572,0.311,1.104,0.22
	c0.07,0.519,0.683,0.493,0.882,0.883c0.135,0.011,0.261,0.032,0.222,0.22H35.7c0.059,0.309,0.484,0.251,0.661,0.44
	c0.724,0.306,1.539,0.521,2.646,0.441c0.331,0.258,0.901,0.274,1.103,0.662c0.17-0.05,0.435-0.006,0.441-0.221
	c0.171-0.049,0.434-0.006,0.441-0.22h1.322c0.641-0.004,0.551,0.357,0.881,0.441c0.26,0.064,0.639-0.105,0.884,0
	c0.026,0.012-0.024,0.197,0,0.22c0.03,0.032,0.176-0.02,0.22,0c0.243,0.104,0.335,0.212,0.661,0.22
	c0.48-0.194,0.887-0.559,1.544-0.661c0.515-0.08,1.069,0.057,1.764,0c0.256,0.185,0.592,0.291,1.103,0.221
	c0.058,0.309,0.484,0.251,0.661,0.44c0.418-0.049,0.487,0.249,0.883,0.22h2.425c0.68-0.164,0.425,0.606,1.104,0.441
	c0.05-0.18,0.239-0.429,0.439-0.441c0.407-0.021,0.714-0.658,0.884-0.881c0.134-0.013,0.259-0.033,0.22-0.22
	c0.127-0.042,0.319,0.055,0.441,0c0.07-0.033,0.138-0.387,0.22-0.441c0.102-0.068,0.327,0.05,0.441,0
	c0.064-0.029,0.149-0.387,0.221-0.441c0.222-0.172,0.438-0.275,0.66-0.44c0.038-0.027-0.032-0.189,0-0.222
	c0.038-0.035,0.177,0.029,0.222,0c0.157-0.099,0.313-0.097,0.44-0.221c1.342-0.325,3.51-0.325,4.852,0
	c0.621-0.187,0.903-0.711,1.764-0.661c0.003-0.365,0.611-0.123,0.66-0.441c0.513,0.071,0.848-0.036,1.104-0.221
	c0.349-0.068,0.759,0.091,1.103,0c0.308-0.081,0.378-0.341,0.661-0.441v-0.44h0.441c0.146-0.295,0.472-0.411,0.882-0.441h0.882
	c0.013-0.136,0.033-0.261,0.221-0.221c0.41-0.03,0.737-0.145,0.883-0.441c-0.311-0.058-0.252-0.483-0.44-0.661
	c-0.331-0.037-0.815,0.079-0.884-0.221c0.016-0.538,0.641-0.396,0.884-0.441c0.213-0.04,0.268-0.146,0.44-0.22
	c0.501-0.16,1.126-0.197,1.543-0.441c0.034-0.26-0.038-0.627,0.22-0.662c0.162-0.602-0.437-0.444-0.439-0.882
	c0.181-0.624-0.312-0.572-0.222-1.103c-0.159-0.674,0.226-0.803,0.222-1.323c0.159-0.673-0.226-0.804-0.222-1.323h1.103
	c0.115-0.326,0.326-0.555,0.441-0.882c0.416-0.024,0.152-0.728,0.661-0.661c0.369-0.366,0.176-1.294,0.22-1.984h-0.22
	c-0.135-0.012-0.262-0.033-0.22-0.22c-0.075,0-0.147,0-0.222,0c-0.135-0.013-0.261-0.034-0.22-0.221
	c-0.314-0.053-0.372-0.363-0.662-0.441c-0.134-0.012-0.26-0.033-0.221-0.221c-0.256-0.037-0.238-0.349-0.22-0.661
	c0.549-0.035,0.683,0.346,1.103,0.441c0.673-0.16,0.803,0.226,1.322,0.22c1.31,0.06,2.26-0.241,3.308-0.441
	c0.05-0.171,0.006-0.436,0.221-0.441C79.629,20.589,79.332,20.52,79.358,20.125 M77.527,15.397
	c-0.003,0.004-0.008,0.006-0.012,0.009C77.52,15.402,77.524,15.401,77.527,15.397z M77.569,15.343
	c-0.003,0.004-0.006,0.008-0.009,0.011C77.563,15.35,77.567,15.347,77.569,15.343L77.569,15.343"/>
    </SvgIcon>
  );
}

export default function USPBlock() {
  const classes = useStyles();

  const iconStyle = { fontSize: 60 };
  const iconClasses = classNames(classes.hasTextShadow, classes.hasPadding);

  const trendingIcon = (
    <SDAIcon
      style={iconStyle}
      className={iconClasses}
      alt="Sugardaddy Community"
    />
  );

  const userIcon = (
    <SDA2Icon
      style={iconStyle}
      className={iconClasses}
      alt="Sugardaddy Plattform"
    />
  );

  const lockIcon = (
    <LockIcon
      style={iconStyle}
      className={iconClasses}
      alt="Seriöse Sugardaddy Plattform"
    />
  );

  const usps = [
    {
      headline: "SUGAR DATING AUSTRIA",
      subline: "Viele Mitglieder aus Österreich für Dates in deiner Nähe",
      icon: userIcon,
    },
    {
      headline: "DU WIRST ES LIEBEN",
      subline: "Die wohl beste Website für Sugar Babes & Sugar Daddys",
      icon: trendingIcon,
    },
    {
      headline: "SICHER FLIRTEN",
      subline: "Strenger EU Datenschutz und Serverstandort in der EU",
      icon: lockIcon,
    },
  ];

  return (
    <div className={classes.rowOdd}>
      <div className={classes.container}>
        <GridContainer
          alignItems="stretch"
          justify="space-between"
          direction="row"
        >
          {usps.map((usp) => (
            <GridItem xs={12} sm={4}>
              <GridContainer alignItems="center">
                <GridItem xs={3}>{usp.icon}</GridItem>
                <GridItem xs={9}>
                  <h6 className={classNames(classes.isOverline)}>
                    {usp.headline}
                  </h6>
                  <h3 className={classNames(classes.isNarrow, classes.isBold)}>
                    {usp.subline}
                  </h3>
                </GridItem>
              </GridContainer>
            </GridItem>
          ))}
        </GridContainer>
      </div>
    </div>
  );
}
